import { LocationSelection } from "common.model/src/types/location/location";
import { Location } from "./types";

export default class DefaultFilterValues {

    public static getEmployerFilters() {
        return {
          lineOfBusiness: {
            include: [],
            exclude: []
          },
          hq: {
            include: [],
            exclude: []
          },
          industries: {
            include: [],
            exclude: []
          },
          planTypes: {
            include: [],
            exclude: []
          },
          entityTypes: {
            include: [],
            exclude: []
          },
          participantRangeFilters: { start: 0, end: 3000000 },
          renewalDateFilters: { start: 1, end: 12 },
          carrierName: {
            include: [],
            exclude: []
          },
          search: '',
          brokerSize:{
            include: [],
            exclude: []
          },
          brokerName: {
            include: [],
            exclude: []
          },
          brokerLocationOffices:{
            include: [],
            exclude: []
          },
        };
    }

    public static getBrokerFilters() {
        return {
          roles: { include: [], exclude: [] },
          jobTitles: { include: [], exclude: [] },
          case: [0,100],
          lineOfBusiness:  {
            include: [],
            exclude: []
          },
          employerHq: {
            include: [] as LocationSelection[],
            exclude: [] as LocationSelection[]
          },
          brokerOffices: {
            include: [] as LocationSelection[],
            exclude: [] as LocationSelection[],
          },
          planTypes: {
            include: [],
            exclude: []
          },
          entityTypes: {
            include: [],
            exclude: []
          },
          participantRanges: { start: 0, end: 3000000 },
          renewalDateFilters: { start: 1, end: 12 },
          industries: {
            include: [],
            exclude: []
          },
          filterPrimaryBroker: false,
          carrierName:  {
            include: [],
            exclude: []
          },
          search: '',
			brokerSize: {
				include: [],
				exclude: [],
			},
        };
    }

    public static getProviderFilters() {
        return {
          states: [],
          search: '',
        };
    }

}