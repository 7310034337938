import { useState } from 'react';
import { PageContainer } from 'new-beginning/components/shared/PageContainer';
import { Tabs, Tab, TabPanel } from 'molecules/Tabs';
import { SalesforceHeader } from './SalesforceHeader';
import { SalesforceSettingContainer } from './SalesforceSettingContainer';
import { FieldMappingTable } from './field_mapping/FieldMappingTable';
import {
	useSalesforceFields,
	SalesforceObjectTypes,
	getFieldMappingOptions,
} from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { useSalesforceFieldMappings } from 'new-beginning/hooks/useCrmMappingData';
import { ObjectMappingTargetType } from 'common.model/src/db/model-decorators/type-extensions';

interface SalesforceSettingsProps {}

const mappingFieldTypes: [ObjectMappingTargetType, SalesforceObjectTypes][] = [
	[ObjectMappingTargetType.PARENT_ACCOUNT, SalesforceObjectTypes.ACCOUNT],
	[ObjectMappingTargetType.CHILD_ACCOUNT, SalesforceObjectTypes.ACCOUNT],
	[ObjectMappingTargetType.CONTACT, SalesforceObjectTypes.CONTACT],
];

export const SalesforceSettings = ({}: SalesforceSettingsProps) => {
	const [activeTab, setActiveTab] = useState<number>(0);

	const [ benefitFlowFieldType, salesforceFieldType ] = mappingFieldTypes[activeTab];
	const { fieldMappings, loading: loadingMappings, clearCache: reloadMappings } = useSalesforceFieldMappings(benefitFlowFieldType);
	const { salesforceFields, loading: loadingFields, clearCache: clearFields } = useSalesforceFields(salesforceFieldType);

	const isLoading = loadingFields || loadingMappings;

	return (
		<PageContainer header={<SalesforceHeader reSyncFields={clearFields} />}>
			<Tabs value={activeTab} onChange={(e, idx: number) => setActiveTab(idx)}>
				<Tab label='Accounts' />
				<Tab label='Child Accounts' />
				<Tab label='Contacts' />
			</Tabs>
			<div>
				{mappingFieldTypes.map(([mappingType, salesforceType], idx) => (
					<TabPanel value={activeTab} index={idx} key={mappingType}>
						<SalesforceSettingContainer>
							{Object.entries(fieldMappings?.[mappingType] || {})?.map(
								([mappingRuleType, mappingCollection]) => (
									<FieldMappingTable
										key={`${mappingType}-${mappingRuleType}`}
										mappingRuleType={mappingRuleType}
										crmObjectType={benefitFlowFieldType}
										fieldMappings={mappingCollection}
										sourceFieldSet={getFieldMappingOptions(benefitFlowFieldType, salesforceType, salesforceFields)}
										targetFieldSet={salesforceFields?.[salesforceType]?.fields}
										loading={isLoading}
										refreshMappingFields={reloadMappings}
									/>
								)
							)}
						</SalesforceSettingContainer>
					</TabPanel>
				))}
			</div>
		</PageContainer>
	);
};
