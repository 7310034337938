
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { resetToInitialState } from '../../../utils/ReduxStateUtils';

export function getInitialContactFilters() {
  return {
    companyName: {
      include: [],
      exclude: [],
    },
    contactType: 'EMPLOYER',
    jobTitles: { include:[], exclude: [] },
    yearsAtCompany: ['0','21'],
    roles: { include:[], exclude: [] },
    contactLocation: { include:[], exclude: [] },
		nameSearch: '',
		employerTotalEmployees: { start: 0, end: 3000000 },
		employerHeadquarters: { include: [], exclude: [] },
		employerIndustries: { include: [], exclude: [] },
		employerEntityType: { include: [], exclude: [] },
		employerPlanType: { include: [], exclude: [] },
		employerLineOfBusiness: { include: [], exclude: [] },
		employerCarrierName: { include: [], exclude: [] },
		employerRenewalDatesRange: { start: 1, end: 12 },
		brokerSize: { include: [], exclude: [] },
  };
}

// The initial state of the EmployerPage container
export const initialState: ContainerState = {
  selectedTab: 0,

	isEmployerContactsLoaded: false,

  employer_master_id: null,

  employerSummary: null,
  feesPaidToServiceProviders: null,
  planBrokerPolicies: null,
  historicalPlanBrokerPolicies: null,

  benefitFilingYears: null,
  allFilingYears: null,

  contactFilters: getInitialContactFilters(),
  anonymizedContacts: null,
  anonymizedContactsBreakdown: null,
  selectedContacts: {},
  selectedContactProfileUrl: null,
  isSelectAllContacts: false,
  contactsCheckedMap: {},
  contactsNotCheckedMap: {},
  isContactsSearchInfiniteLoading: false,
  contactSearchInfinitePage: 0,

  gettingSelectedMap: {},
  isGetSelectedLoading: false,
  isDownloading: false,

  showPurchaseModal: false,
  purchaseModalAmount: null,

  filingContacts: null,
  activeParticipantsTrend: null,

  selectedBenefitFilingYear: null,
  selectedAllFilingYear: null,

  showCreateContactListModal: false,
  listAddLoading: false,

  contactLocationSearchOptions: null,
  isContactLocationSearchOptionsLoading: false,
};

const employerPageSlice = createSlice({
  name: 'employerPage',
  initialState,
  reducers: {
    load(state, action: PayloadAction<any>) {
      state.isEmployerContactsLoaded = true;
      const previousContactFilters = state.contactFilters;
      resetToInitialState(state, initialState);
      state.contactFilters = {
        ...previousContactFilters,
        ...getInitialContactFilters(),
        companyMasterIds: [action.payload],
      };
      state.employer_master_id = action.payload;
    },
    setSelectedTab(state, action: PayloadAction<any>) {
      state.selectedTab = action.payload;
    },
    updateEmployerSummary(state, action: PayloadAction<any>) {
      state.employerSummary = action.payload;
    },
    updateFeesPaidToServiceProviders(state, action: PayloadAction<any>) {
      state.feesPaidToServiceProviders = action.payload;
    },
    updateBenefitFilingYears(state, action: PayloadAction<any>) {
      state.benefitFilingYears = action.payload;
      if (!state.selectedBenefitFilingYear) {
        state.selectedBenefitFilingYear = action.payload.length > 0 ? action.payload.reduce(
          (a, b) => Math.max(a, b),
          0,
        ) : null;
      }
    },
    updateAllFilingYears(state, action: PayloadAction<any>) {
      state.allFilingYears = action.payload;
      if (!state.selectedAllFilingYear) {
        state.selectedAllFilingYear = action.payload.length > 0 ? action.payload.reduce(
          (a, b) => Math.max(a, b),
          0,
        ) : null;
      }
    },
    loadPlanBrokerPolicies(state, action: PayloadAction<any>) {
      state.planBrokerPolicies = null;
      state.employer_master_id = action.payload['employer_master_id'];
    },
    updatePlanBrokerPolicies(state, action: PayloadAction<any>) {
      state.planBrokerPolicies = action.payload;
    },
    updateHistoricalPlanBrokerPolicies(state, action: PayloadAction<any>) {
      state.historicalPlanBrokerPolicies = action.payload;
    },
    updateSelectedBenefitFilingYear(state, action: PayloadAction<any>) {
      state.selectedBenefitFilingYear = action.payload;
      state.planBrokerPolicies = null;
    },
    updateSelectedAllFilingYear(state, action: PayloadAction<any>) {
      state.selectedAllFilingYear = action.payload;
      state.filingContacts = null;
    },
    updateFilingContacts(state, action: PayloadAction<any>) {
      state.filingContacts = action.payload;
    },
    updateContacts(state, action: PayloadAction<any>) {
      const existingContacts = state.anonymizedContacts || [];
      state.anonymizedContacts = existingContacts.concat(action.payload["anonymizedContacts"]);
      state.selectedContacts = action.payload["selectedContacts"]
        ?.reduce((a,b) => {
          a[b.profile_url] = b;
          return a; 
        }, state.selectedContacts || {});

      state.isContactsSearchInfiniteLoading = false;
    },
    updateContactsBreakdown(state, action: PayloadAction<any>) {
      state.anonymizedContactsBreakdown = action.payload;
    },
    getContact(state, action: PayloadAction<any>) {
      state.selectedContactProfileUrl = action.payload;
    },
    gotContact(state, action: PayloadAction<any>) {
      state.selectedContacts[action.payload.profile_url] = action.payload;
      state.selectedContactProfileUrl = null;
    },
    getSelected(state, action: PayloadAction<string[]>) {
      state.gettingSelectedMap = action.payload
        .reduce((a, b) => {
          a[b] = true;
          return a;
        }, state.gettingSelectedMap);
        state.isGetSelectedLoading = true;
    },
    gotSelected(state, action: PayloadAction<any>) {
      state.selectedContacts = action.payload
        .reduce((a, b) => {
          a[b.profile_url] = b;
          return a;
        }, state.selectedContacts);
      state.gettingSelectedMap = {};
      state.isGetSelectedLoading = false;
    },
    downloadSearchResults(state) {
      state.isDownloading = true;
    },
    downloadedSearchResults(state) {
      state.isDownloading = false;
    },
    toggleCreateContactListModal(state) {
      state.showCreateContactListModal = !state.showCreateContactListModal;
    },
    addContactsToExistingList(state, action: PayloadAction<{ listId: string }>) {
      state.listAddLoading = true;
    },
    addContactsToNewList(state, action: PayloadAction<{ listName: string, listDescription }>) {
      state.listAddLoading = true;
    },
    addContactsToListFinished(state) {
      state.listAddLoading = false;
    },
    showPurchaseModal(state, action: PayloadAction<number>) {
      state.showPurchaseModal = true;
      state.purchaseModalAmount = action.payload;
    },
    hidePurchaseModal(state) {
      state.showPurchaseModal = false;
      state.purchaseModalAmount = null;
    },
    searchContactsInfinite(state) {
      state.isContactsSearchInfiniteLoading = true;
      state.contactSearchInfinitePage += 1;
    },
    updateContactsCheckedMap(state, action: PayloadAction<any>) {
      state.contactsCheckedMap = action.payload;
    },
    updateContactsNotCheckedMap(state, action: PayloadAction<any>) {
      state.contactsNotCheckedMap = action.payload;
    },
    setIsSelectAllContacts(state, action: PayloadAction<any>) {
      state.isSelectAllContacts = action.payload;
    },
    updateContactFilters(state, action: PayloadAction<any>) {
      state.contactFilters = {
        ...action.payload,
				...(state.employer_master_id ? { companyMasterIds: [state.employer_master_id] } : {}),
      };
      state.isSelectAllContacts = false;
      state.contactsCheckedMap = {};
      state.contactsNotCheckedMap = {};
      state.anonymizedContacts = null;
      state.anonymizedContactsBreakdown = null;
      state.contactSearchInfinitePage = 0;
    },
    resetContactSearchFilters(state) {
      state.contactFilters = getInitialContactFilters();
      state.isSelectAllContacts = false;
      state.contactsCheckedMap = {};
      state.contactsNotCheckedMap = {};
      state.anonymizedContacts = null;
      state.anonymizedContactsBreakdown = null;
      state.contactSearchInfinitePage = 0;
      state.employer_master_id = null;
    },
    updateActiveParticipantsTrend(state, action: PayloadAction<any>) {
      state.activeParticipantsTrend = action.payload;
    },
    searchCityStateOptions(state, action: PayloadAction<string>) {
      state.isContactLocationSearchOptionsLoading = true;
    },
    updateContactLocationSearchOptions(state, action: PayloadAction<any>) {
      state.contactLocationSearchOptions = action.payload;
      state.isContactLocationSearchOptionsLoading = false;
    },
  },
});

export const {
  actions: employerPageActions,
  reducer,
  name: sliceKey,
} = employerPageSlice;
