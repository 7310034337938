// ui.core/src/app/containers/SearchPage/slice.ts

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { filterBarActions } from '../FilterBar/slice';
import { ContainerState } from './types';
import { brokerSearchPageActions } from './containers/BrokerSearchPage/slice';
import ExportType from 'common.model/src/types/data-export/ExportType';

export const initialState: ContainerState = {
  searchBy: 0,

  isBrokerLoaded: false,
  isPlanSearchInfiniteLoading: false,
  employerSearchInfinitePage: 0,
  employerResults: null,
  employerResultsBreakdown: null,
  employerHqResults: null,
  employerHqSearch: '',
  isEmployerHqOptionsLoading: false,
  isUpdateParticipants: false,
  isUpdatePlanRenewalDate: false,
  employerCarrierResults: null,
  isEmployerCarrierOptionsLoading: false,
  employerCarrierSearch: '',

  isProviderSearchInfiniteLoading: false,
  providerSearchInfinitePage: 0,
  providerResults: null,
  providerResultsBreakdown: null,

  isBrokerSearchInfiniteLoading: false,
  brokerSearchInfinitePage: 0,
  brokerResults: null,
  brokerResultsBreakdown: null,
  downloadStarted: false,
  brokerEmployerHqResults: null,
  brokerEmployerHqOptionSearch: '',
  isBrokerEmployerHqOptionsLoading: false,
  brokerOfficeOptionResults: null,
  brokerOfficeOptionSearch: '',
  isBrokerOfficeOptionsLoading: false,
  selectedBrokerTab: "Accounts",
	selectedEmployerTab: 'Accounts',
  brokerCarrierResults: null,
  isBrokerCarrierOptionsLoading: false,
  brokerCarrierSearch: ''
  

  // isBrokerUpdateParticipants: false,
  // isBrokerUpdatePlanRenewalDate: false,
};

const searchPageSlice = createSlice({
  name: 'searchPage',
  initialState,
  reducers: {
    load(state) {
      state.isBrokerLoaded = true;
    },
    updateSearchBy(state, action: PayloadAction<any>) {
      state.searchBy = action.payload;
      state.brokerSearchInfinitePage = 0;
      state.providerSearchInfinitePage = 0;
      state.employerSearchInfinitePage = 0;
    },
    searchProvider(state) {},
    searchBroker(state) {},
    searchEmployer(state) {},
    updateProviderResults(state, action: PayloadAction<any>) {
      state.providerResults = action.payload;
    },
    updateProviderResultsBreakdown(state, action: PayloadAction<any>) {
      state.providerResultsBreakdown = action.payload;
    },
    updateEmployerResults(state, action: PayloadAction<any>) {
      state.employerResults = action.payload;
    },
    updateEmployerResultsBreakdown(state, action: PayloadAction<any>) {
      state.employerResultsBreakdown = action.payload;
    },
    updateEmployerHqOptionsResults(state, action: PayloadAction<any>) {
      state.employerHqResults = action.payload;
      state.isEmployerHqOptionsLoading = false;
    },
    updateBrokerEmployerHqOptionsResults(state, action: PayloadAction<any>) {
      state.brokerEmployerHqResults = action.payload;
      state.isBrokerEmployerHqOptionsLoading = false;
    },
    updateBrokerOfficeOptionsResults(state, action: PayloadAction<any>) {
      state.brokerOfficeOptionResults = action.payload;
      state.isBrokerOfficeOptionsLoading = false;
    },
    updateBrokerResults(state, action: PayloadAction<any>) {
      state.brokerResults = action.payload;
    },
    updateBrokerResultsBreakdown(state, action: PayloadAction<any>) {
      state.brokerResultsBreakdown = action.payload;
    },
    searchEmployerInfinite(state) {
      state.isPlanSearchInfiniteLoading = true;
      state.employerSearchInfinitePage += 1;
    },
    searchProviderInfinite(state) {
      state.isProviderSearchInfiniteLoading = true;
      state.providerSearchInfinitePage += 1;
    },
    searchBrokerInfinite(state) {
      state.isBrokerSearchInfiniteLoading = true;
      state.brokerSearchInfinitePage += 1;
    },
    updateInfiniteEmployerResults(state, action: PayloadAction<any>) {
      state.employerResults = state.employerResults.concat(action.payload);
      state.isPlanSearchInfiniteLoading = false;
    },
    updateInfiniteProviderResults(state, action: PayloadAction<any>) {
      state.providerResults = state.providerResults.concat(action.payload);
      state.isProviderSearchInfiniteLoading = false;
    },
    updateInfiniteBrokerResults(state, action: PayloadAction<any>) {
      state.brokerResults = state.brokerResults.concat(action.payload);
      state.isBrokerSearchInfiniteLoading = false;
    },
    exportEmployer(state, action: PayloadAction<ExportType>) {},
    exportBroker(state, action: PayloadAction<ExportType>) {},
    downloadStarted(state, action: PayloadAction<boolean>) {
      state.downloadStarted = action.payload;
    },
    searchEmployerHq(state, action: PayloadAction<string>) {
      state.isEmployerHqOptionsLoading = true;
      state.employerHqSearch = action.payload;
    },
    searchBrokerEmployerHqOptions(state, action: PayloadAction<string>) {
      state.isBrokerEmployerHqOptionsLoading = true;
      state.brokerEmployerHqOptionSearch = action.payload;
    },
    searchBrokerOfficeOptions(state, action: PayloadAction<string>) {
      state.isBrokerOfficeOptionsLoading = true;
      state.brokerOfficeOptionSearch = action.payload;
    },
    setSelectedBrokerTab(state, action: PayloadAction<string>) {
      state.selectedBrokerTab = action.payload;
    },
    setSelectedEmployerTab(state, action: PayloadAction<string>) {
      state.selectedEmployerTab = action.payload;
    },
    searchBrokerCarrierOptions(state, action: PayloadAction<string>) {
      state.isBrokerCarrierOptionsLoading = true;
      state.brokerCarrierSearch = action.payload;
    },
    updateBrokerCarrierOptionsResults(state, action: PayloadAction<any>) {
      state.brokerCarrierResults = action.payload;
      state.isBrokerCarrierOptionsLoading = false;
    },
    searchEmployerCarrierOptions(state, action: PayloadAction<string>) {
      state.isEmployerCarrierOptionsLoading = true;
      state.employerCarrierSearch = action.payload;
    },
    updateEmployerCarrierOptionsResults(state, action: PayloadAction<any>) {
      state.employerCarrierResults = action.payload;
      state.isEmployerCarrierOptionsLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(filterBarActions.updateBrokerFilters, (state, action) => {
        state.isBrokerLoaded = true;
        state.brokerResults = null;
        state.brokerResultsBreakdown = null;
        state.brokerSearchInfinitePage = 0;
        if (action.payload.fieldChanged != "employerHq") {
          // state.brokerEmployerHqResults = null;
          state.brokerEmployerHqOptionSearch = '';
        }
        if (action.payload.fieldChanged != "brokerOffices") {
          // state.brokerOfficeOptionResults = null;
          state.brokerOfficeOptionSearch = '';
        }
      })
      .addCase(filterBarActions.updateEmployerFilters, (state, action) => {
        state.employerSearchInfinitePage = 0;
        state.employerResults = null;
        state.employerResultsBreakdown = null;
        if (action.payload.fieldChanged != "hq") {
          // state.employerHqResults = null;
          state.employerHqSearch = '';
        }
      })
      .addCase(filterBarActions.updateProviderFilters, (state, action) => {
        state.providerSearchInfinitePage = 0;
      })
      .addCase(filterBarActions.clearFilters, (state) => {
        switch (state.searchBy) {
          case 1:
            state.employerSearchInfinitePage = 0;
            state.employerHqResults = null;
            state.employerHqSearch = '';
            state.isEmployerHqOptionsLoading = false;
            break;
          case 2:
            state.providerSearchInfinitePage = 0;
            break;
          case 0:
            state.isBrokerLoaded = true;
            state.brokerSearchInfinitePage = 0;

            state.brokerEmployerHqResults = null;
            state.brokerEmployerHqOptionSearch = '';
            state.isBrokerEmployerHqOptionsLoading = false;

            state.brokerOfficeOptionResults = null;
            state.brokerOfficeOptionSearch = '';
            state.isBrokerOfficeOptionsLoading = false;

            break;
        }
      })
  }
});


export const {
  actions: searchPageActions,
  reducer,
  name: sliceKey,
} = searchPageSlice;
