import { FunctionComponent, useState } from 'react';
import cn from 'classnames';
import { MappingTableRows } from 'new-beginning/components/pages/IntegrationSettings/field_mapping/MappingTableRows';
import { DropDownOptionType } from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { useUpdateCrmMappingFields } from 'new-beginning/hooks/salesforce/useUpdateCrmMappingFields';
import { ObjectMappingTargetType, DecoratedFieldMapping, StagedDecoratedFieldMapping, ObjectMappingRuleType } from 'common.model/src/db/model-decorators/type-extensions';

enum MappingRuleHeader {
	SHARED = 'Shared',
	BROKER = 'Broker',
	EMPLOYER = 'Employer',
}

enum CrmObjectMappingLevel {
	PARENT_ACCOUNT = "Accounts",
	CHILD_ACCOUNT = 'Child Accounts',
	CONTACT = 'Contacts',
}

export const mappingSectionSubtext:
	Record<ObjectMappingRuleType, FunctionComponent<{ objectType: ObjectMappingTargetType }>> =
	{
		[ObjectMappingRuleType.SHARED]: ({ objectType }) => (
			<p className={cn('bf-txt', 'txt-sm', 'txt-lighter')}>
				These field mappings apply to both <strong>Broker {CrmObjectMappingLevel[objectType]}</strong> and <strong>Employer {CrmObjectMappingLevel[objectType]}</strong>
			</p>
		),
		[ObjectMappingRuleType.BROKER]: ({ objectType }) => (
			<p className={cn('bf-txt', 'txt-sm', 'txt-lighter')}>
				These field mappings will only apply to <strong>Broker {CrmObjectMappingLevel[objectType]}</strong>
			</p>
		),
		[ObjectMappingRuleType.EMPLOYER]: ({ objectType }) => (
			<p className={cn('bf-txt', 'txt-sm', 'txt-lighter')}>
				These field mappings will only apply to <strong>Employer {CrmObjectMappingLevel[objectType]}</strong>
			</p>
		),
};

interface FieldMappingTableProps {
	loading: boolean;
	fieldMappings: DecoratedFieldMapping[];
	sourceFieldSet: DropDownOptionType[];
	targetFieldSet: DropDownOptionType[];
	crmObjectType: ObjectMappingTargetType;
	mappingRuleType: ObjectMappingRuleType;
	refreshMappingFields: () => void;
}

export const FieldMappingTable = ({
	loading,
	fieldMappings,
	crmObjectType,
	sourceFieldSet = [],
	targetFieldSet = [],
	refreshMappingFields,
	mappingRuleType,
}: FieldMappingTableProps) => {
	const [existingFieldEdits, setExistingFieldEdits] = useState<{ [key: number]: DecoratedFieldMapping }>({});
	const [existingMappingsToDelete, setExistingMappingsToDelete] = useState<Record<number, number>>({});
	const [newMappingFields, setNewMappingFields] = useState<StagedDecoratedFieldMapping[]>([]);
	const { updateFieldMappings, loading: saveEditsLoading, requestApiSuccess, requestErrorMessage } = useUpdateCrmMappingFields();

	const resetMappingEdits =() => {
		setExistingFieldEdits({});
		setNewMappingFields([]);
		setExistingMappingsToDelete({});
	};
	const saveFieldMappings = () => {
		const existingEditsFlattened = Object.values(existingFieldEdits).filter((val) => !existingMappingsToDelete?.[val?.id]);
		const fieldsToDelete = Object.values(existingMappingsToDelete);
		updateFieldMappings(newMappingFields, existingEditsFlattened, fieldsToDelete)
			.then(() =>refreshMappingFields())
			.then(() => {
				resetMappingEdits();
			})
			.catch((err) => {
				console.error('Error Saving Fields: ', err);
			});
	};

	const editExistingMappingField = (index, values: Partial<DropDownOptionType>) => {
		const originalField = fieldMappings[index];
		const mappingFieldId = originalField?.id;
		const fieldWithEdits = { ...originalField, ...existingFieldEdits?.[mappingFieldId] };
		setExistingFieldEdits({
			...existingFieldEdits,
			[mappingFieldId]: {
				...fieldWithEdits,
				...values,
			},
		});
	};

	const deleteExistingMapping = (mappingId: number) => {
		setExistingMappingsToDelete({...existingMappingsToDelete, [mappingId]: mappingId});
	};

	const addNewMappingField = () =>
		setNewMappingFields([
			...newMappingFields,
			{
				id: null,
				crm_type: 'SALESFORCE',
				sourceField: {
					name: null,
					value: null,
					field_domain_type: null,
				},
				targetField: {
					name: null,
					field_domain_type: null,
				},
				source_field_name: null,
				target_field_name: null,
				matching_field: false,
				required_field: false,
				allow_overwrite: false,
				source_object_type: null,
				mapping_rule_type: mappingRuleType,
				target_object_type: crmObjectType,
				mapped_constant_value: null,
			},
		]);

	const updateNewMappingField = (mappingIdx: number, values: Partial<DropDownOptionType>) => {
		const updatedMappingSet: StagedDecoratedFieldMapping[] = newMappingFields.map((field, idx) =>
			idx == mappingIdx ? { ...field, ...values } : field
		);
		setNewMappingFields(updatedMappingSet);
	};

	const deleteFieldMapping = (key: number | null = null) => {
		setNewMappingFields(newMappingFields.filter((_, idx) => idx !== key));
	};

	const hasActiveEdits =
		newMappingFields.length > 0
		|| Object.keys(existingFieldEdits).length > 0
		|| Object.keys(existingMappingsToDelete).length > 0;
	const hasIncompleteEdits = newMappingFields.some((value) => value?.targetField?.name === null);

	const hasNonUniqueTargetFields = (): boolean => {
		const getTarget = (val: DecoratedFieldMapping) => val?.targetField?.name;
		const editedIds = new Set(Object.keys(existingFieldEdits));
		const existingEdits = Object.values(existingFieldEdits).map(getTarget) || [];
		const newFields = newMappingFields?.map(getTarget) || [];

		const uniqueEditFields = new Set([...existingEdits, ...newFields]);
		const uniqueExistingFields = new Set(
			fieldMappings?.filter((mapping) => mapping.id && !editedIds.has(mapping.id.toString()))?.map(getTarget) || []
		);

		return [...uniqueEditFields].some((newTargetField) => uniqueExistingFields.has(newTargetField));
	};
	const hasInvalidEdits = hasIncompleteEdits || hasNonUniqueTargetFields();
	const MappingSubtext = mappingSectionSubtext?.[mappingRuleType];

	return (
		<div className={cn('bg-white', 'rounded', 'mt-3', 'p-4')}>
			<div className={cn('px-2', 'mt-2', 'text-muted')}>
				<p className={cn('bf-txt', 'txt-neutral', 'txt-bolder', 'txt-md', 'pb-1')}>{MappingRuleHeader[mappingRuleType]} Mappings</p>
				<MappingSubtext objectType={crmObjectType} />
			</div>
			<table className={cn('table', 'bf-table', 'salesforce-mapping-table')}>
				<thead>
					<tr>
						<th>Source</th>
						<th />
						<th>Target</th>
						<th className={cn('text-center')}>Overwrite Behavior</th>
						<th />
					</tr>
				</thead>
				<tbody>
					<MappingTableRows
						setMappingField={editExistingMappingField}
						deleteFieldMapping={deleteExistingMapping}
						fieldMappings={fieldMappings?.filter((mapping) => !existingMappingsToDelete?.[mapping?.id])}
						existingFieldEdits={existingFieldEdits}
						sourceFieldSet={sourceFieldSet}
						targetFieldSet={targetFieldSet}
						loading={loading}
						crmObjectType={crmObjectType}
					/>
					<MappingTableRows
						setMappingField={updateNewMappingField}
						deleteFieldMapping={deleteFieldMapping}
						fieldMappings={newMappingFields}
						sourceFieldSet={sourceFieldSet}
						targetFieldSet={targetFieldSet}
						loading={loading}
						crmObjectType={crmObjectType}
					/>
					{!fieldMappings?.length && !newMappingFields?.length && (
						<tr>
							<td className={cn('p-3')} align='center' colSpan={7}>
								{loading ? (
									<div className='text-primary'>
										<span className={cn('spinner-border', 'spinner-border-lg', 'mx-2')} />
									</div>
								) : (
									<p className={cn('bf-txt', 'txt-lg')}>
										No Field Mappings exist for {MappingRuleHeader[mappingRuleType]} {CrmObjectMappingLevel[crmObjectType]} yet
									</p>
								)}
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<div className={cn('d-flex', 'justify-content-between', 'pt-2')}>
				<div className={cn('px-2')}>
					<button onClick={() => addNewMappingField()} disabled={loading} className={cn('btn', 'btn-outline-dark')}>
						Add Field Mapping
					</button>
				</div>
				<div className={cn('px-2')}>
					<button
						onClick={resetMappingEdits}
						disabled={loading || !hasActiveEdits}
						className={cn('btn', 'btn-outline-secondary', 'me-3')}
					>
						Cancel
					</button>
					<button
						onClick={saveFieldMappings}
						disabled={loading || saveEditsLoading || !hasActiveEdits || hasInvalidEdits}
						className={cn('btn', 'btn-primary')}
					>
						{!saveEditsLoading && <span>Save Changes</span>}
						{saveEditsLoading && (
							<span>
								<span className={cn('spinner-border', 'spinner-border-sm')} />
								<span className={cn('ms-2')}>Saving</span>
							</span>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};
