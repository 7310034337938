import { useState, useEffect } from 'react';
import serviceUrl from 'utils/serviceUrl';
import axios from 'axios';
import { useBasicApiRequestState, BasicApiRquestState, BaseApiReponse } from '../useBasicApiRequestState';
import { CrmIntegrationFieldDomainType, ObjectMappingTargetType } from 'common.model/src/db/model-decorators/type-extensions';
import { BENEFIT_FLOW_CRM_OBJECTS } from 'common.model/src/types/integration/fieldMapping/BenefitFlowFields';
import IField from 'common.model/src/types/integration/fieldMapping/IField';

export enum SourceOrTarget {
	SOURCE = 'SOURCE',
	TARGET = 'TARGET',
}

export enum MappingFieldKeys {
	SOURCE = 'sourceField',
	TARGET = 'targetField',
}

export interface DomainOptionHeader {
	label: string;
	isHeaderOption: boolean;
	field_domain_type: CrmIntegrationFieldDomainType;
}

export interface CrmObjectType {
	name: string;
	label: string;
	type: string;
	value?: string;
	field_domain_type: CrmIntegrationFieldDomainType;
	// Dropdown-Specific Field (Only exists on Frontend)
	isHeaderOption?: boolean;
}

interface CrmPicklistSubtype {
	type: 'picklistOption';
	active: boolean;
	label: string;
	value: string;
}

export interface BenefitFlowField extends CrmObjectType {}

export interface SalesforceField extends CrmObjectType {
	compoundFieldName: string;
	picklistValue?: CrmPicklistSubtype;
}

export type DropDownOptionType = SalesforceField | BenefitFlowField | Partial<CrmObjectType>;

export type GroupedOptionSet = {
	label: string;
	field_domain_type: CrmIntegrationFieldDomainType;
	options: DropDownOptionType[];
};

export enum SalesforceObjectTypes {
	ACCOUNT = 'ACCOUNT',
	CONTACT = 'CONTACT',
}

const decorateFieldOptions = (
	options: IField[] = [],
	groupLabel: string,
	field_domain_type: CrmIntegrationFieldDomainType
): GroupedOptionSet[] => {
	return [{ label: groupLabel, options, field_domain_type }];
};

export const getFieldMappingOptions = (
	bfObjectType: ObjectMappingTargetType,
	sfObjectType: SalesforceObjectTypes,
	salesforceFields: SalesforceFieldsCache,
) => {
	const relevantBenefitFlowFields = BENEFIT_FLOW_CRM_OBJECTS?.[bfObjectType] || [];

	return [
		...decorateFieldOptions(
			relevantBenefitFlowFields,
			'BenefitFlow Fields',
			CrmIntegrationFieldDomainType.BENEFIT_FLOW
		),
		...decorateFieldOptions(
			salesforceFields?.[sfObjectType]?.picklist,
			'Saleforce Constants',
			CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT
		),
		{
			label: 'Custom Value',
			field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
			options: [
				{
					name: 'userDefinedField',
					label: 'Custom Value',
					field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
				},
			]
		},
	];
};

interface SalesforceFieldsCache {
	[fieldType: string]: {
		fields: SalesforceField[];
		picklist: SalesforceField[];
	};
}

export interface SalesForceFields extends BaseApiReponse {
	salesforceFields: SalesforceFieldsCache;
	clearCache: () => void;
}

const unsupportedSalesforceTypes = new Set([
	'formula',
	'lookup',
	'double',
	'date',
	'datetime',
	'multipicklist',
]);
const salesforceFieldNamesUrl = `${serviceUrl}/crm-integration/getSalesforceFieldNames`;
export const useSalesforceFields = (salesforceObjectType: string): SalesForceFields => {
	const {
		baseHeaders,
		tokenLoading,
		loading,
		setLoading,
		requestApiSuccess,
		setApiRequestSuccess,
		requestErrorMessage,
		setRequestErrorMessage,
	}: BasicApiRquestState = useBasicApiRequestState();

	const [salesforceFields, setSalesforceFields] = useState<SalesforceFieldsCache | {}>({});
	const setCacheKey = (objecType, data) =>
		setSalesforceFields(
			Object.assign({}, salesforceFields, {
				[objecType]: data,
			})
		);

	const fetchFieldNames = () => {
		setLoading(true);
		return axios
			.post(salesforceFieldNamesUrl, { objectName: salesforceObjectType }, { headers: { ...baseHeaders } })
			.then((res) => {
				setApiRequestSuccess(true);
				const supportedFields = (res?.data?.res?.data?.fields || [])
					.filter((field) => !unsupportedSalesforceTypes.has(field?.type?.toLowerCase()))
					.reduce(
						(accum, { type, name, label, soapType, picklistValues, defaultValue, extraTypeInfo, compoundFieldName }) => {
							const field: SalesforceField = {
								type,
								name,
								label,
								compoundFieldName,
								field_domain_type: CrmIntegrationFieldDomainType.SALESFORCE,
							};
							if (!picklistValues?.length) {
								accum.fields.push(field);
							} else {
								const flattenedPicklistValues = picklistValues.map(({ label, value, active }) => ({
									...field,
									field_domain_type: CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT,
									picklistValue: {
										type: 'picklistOption',
										active,
										label,
										value,
									},
								}));

								accum.picklist.push(...flattenedPicklistValues);
							}
							return accum;
						},
						{ picklist: [], fields: [] }
					);
				setCacheKey(salesforceObjectType, supportedFields);
			})
			.catch((err: Error) => {
				setRequestErrorMessage(err.message);
				setApiRequestSuccess(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const clearSalesforceFieldCache = () => {
		setSalesforceFields({});
		fetchFieldNames();
	};

	useEffect(() => {
		if (!Object.keys(salesforceFields).length) {
			fetchFieldNames();
		}
	}, [salesforceFields]);

	useEffect(() => {
		if (baseHeaders?.Authorization && !salesforceFields?.[salesforceObjectType]) {
			fetchFieldNames();
		} else if (!tokenLoading && !salesforceFields?.[salesforceObjectType]) {
			console.warn('Cannot Fetch Client Salesforce Fields without Token');
		}
	}, [salesforceObjectType, baseHeaders?.Authorization]);

	return {
		salesforceFields,
		clearCache: clearSalesforceFieldCache,
		loading,
		requestApiSuccess,
		requestErrorMessage,
	};
};
